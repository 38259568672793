import(/* webpackMode: "eager", webpackExports: ["ClientSideFrontPage"] */ "/vercel/path0/apps/frontend/src/app/[locale]/sites/[site]/page.client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useCommonData"] */ "/vercel/path0/apps/frontend/src/components/layout/CommonDataProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.4.2_@sanity+client@6.20.1_@sanity+icons@3.2.0_react@18.3.1__@sanity+types@3.47._4wdo6e2sefuixoib54ah5bxgqu/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.23.3_@opentelemetry+api@1.7.0_@playwright+test@1.47.2_react-dom@18._ys2z7raajb5zxibyjgfczj2aje/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.23.3_@opentelemetry+api@1.7.0_@playwright+test@1.47.2_react-dom@18._ys2z7raajb5zxibyjgfczj2aje/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/isInsideAnIframe.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/common/src/useIsIframeReady.ts");
